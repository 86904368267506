<template>
  <section class="section--press-logos">
    <div class="container">
      <div class="press-logos">
        <div class="press-logo-wrapper press-logo-wrapper--label">
          <small>AS FEATURED IN</small>
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="The Information"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1628160942/app_assets/static_pages/press/press-the-information-logo.png"
            class="press-logo"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="FORTUNE"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-fortune-logo.png"
            class="press-logo"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="Inc."
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-inc.png"
            class="press-logo press-logo--inc"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="BUSINESS INSIDER"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-business-insider-logo.png"
            class="press-logo press-logo--business-insider"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="Forbes"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-forbes-logo.png"
            class="press-logo press-logo--forbes"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="TechCrunch"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-tech-crunch-logo.png"
            class="press-logo"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="ebn"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-ebn.png"
            class="press-logo press-logo--ebn"
          />
        </div>

        <div class="press-logo-wrapper">
          <img
            alt="REUTERS"
            src="https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_3,f_auto,q_auto:good,h_23/v1635791688/app_assets/static_pages/press/press-reuters-logo.png"
            class="press-logo press-logo--reuters"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'press-logos',
};
</script>

<style lang="scss" scoped>
.section--press-logos {
  background-color: var(--viridian-5);
  border-top: 1px solid white;
  overflow: hidden;
}

.press-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.press-logo-wrapper {
  padding: 15px 18px 16px;

  &--label {
    min-width: 125px;
    color: var(--gray-40);
    padding-right: 8px;
  }
}

.press-logo {
  opacity: 0.5;
  height: 23px;

  &--techcrunch {
    opacity: 0.4;
  }

  &--reuters {
    opacity: 0.75;
  }

  &--ebn {
    opacity: 0.35;
  }

  &--inc {
    opacity: 0.25;
  }

  &--business-insider {
    opacity: 0.7;
  }
}
</style>
