import Vue from 'vue';
import { initSentry } from '@utils';
import Cloudinarize from '@plugins/cloudinarize';
import initializeGoogleMaps from '@services/google_maps_initializer';
import PublicDirectory from '../src/static/components/public-directory.vue';

Vue.use(Cloudinarize);

document.addEventListener('DOMContentLoaded', () => {
  initSentry();
  initializeGoogleMaps();

  // eslint-disable-next-line no-unused-vars
  const staticApp = new Vue({
    el: '#public_directory_vue',
    components: {
      PublicDirectory,
    },
  });
});
