<template>
<div class="public-directory-page">
  <section v-if="sponsorName"
    class="section--top-no-nav section--public-top section--sponsor"
  >
    <div class="container">
      <div class="public-directory-page__sponsor">
        <a v-if="sponsorLogoUrl"
          :href="sponsorLink"
          target="_blank">
          <img
            :alt="sponsorName"
            :src="sponsorLogoUrl"
            class="public-directory-page__sponsor-logo"
          />
        </a>

        <a :href="sponsorLink"
          class="public-directory-page__sponsor-link"
          target="_blank">
          Sponsored by {{ sponsorName }}
        </a>
      </div>
    </div>
  </section>

  <section
    class="section--public-hero"
    :class="{ 'section--top-no-nav section--public-top': !sponsorName }"
  >
    <div class="container">
      <h1>{{ marketplaceLocation }} Child Care Openings</h1>

      <p>Join thousands of {{ marketplaceLocation }} area families
        <span class="br-small">finding care in minutes, not months.</span></p>

      <p>Explore open spots near you.</p>

      <location-input
        @set-location="setSortLocation"
        class="public-directory-page__location-form"
        :hideDefaultAddress="true"
        searchPlaceholder="Your address or zipcode..."
        leftLabel="Start here"
        ref="locationInput"
      />
    </div>
  </section>

  <press-logos v-if="!sponsorName" />

  <section>
    <div class="container">
      <div class="cards-wrapper">
        <div class="results-description">
          <p v-if="this.facilities.length">
            {{ sortedByLocation ? 'Sorting' : 'Showing' }}
            {{ facilities.length }} of {{ totalResults }}
            provider{{ totalResults === 1 ? '' : 's' }} near
            <span
              v-if="sortedByLocation"
              class="location-sort"
            >
              <span class="fal fa-map-marker-alt"></span>
              {{ sortLocation.targetAddress }}
              <button class="btn-clear-location" @click="clearLocation">
                <span class="fa fa-times"></span>
              </button>
            </span>
            <span v-else>
              {{ marketplaceLocation }}
            </span>
          </p>
          <p v-else>
            ...
          </p>
        </div>

        <div class="cards">
          <public-facility-card
            v-for="facility in facilities"
            :facility="facility"
            :key="'facilityCard__' + facility.id"
            :facility-id="facility.id"
            :sorted-by-location="sortedByLocation"
            :utm-params="marketplaceUtmParams"
          />
        </div>

        <div class="load-more">
          <system-button
            @click="loadMore"
            v-if="showLoadMoreButton"
          >
            Show {{ nextPageFacilitiesCount }} more
            provider{{ nextPageFacilitiesCount === 1 ? '' : 's' }}
          </system-button>
          <loader :loading="isLoadingFacilities" />
        </div>
      </div>
    </div>
  </section>

  <section class="section--white-green subscribe-bottom-section">
    <div class="container">
      <div class="section-label text-center">
        New openings in your inbox
      </div>

      <h4 class="subscribe-bottom-section__title">
        Subscribe for instant notifications when new spots become available near you!
      </h4>

      <button
        type="button"
        data-toggle="modal"
        data-target="#subscribeModal"
        class="button-subscribe"
        @click="trackClickSubscribe"
      >
        Subscribe for real-time updates
      </button>
    </div>
  </section>

  <press-logos v-if="sponsorName" />
</div>
</template>

<script>
import { axios, toCamelCase } from '@utils';
import LocationInput from '@components/location-input.vue';
import Loader from '@components/loader/loader.vue';
import SystemButton from '@components/buttons/system-button.vue';
import PublicFacilityCard from './public-facility-card.vue';
import PressLogos from './press-logos.vue';

export default {
  name: 'public-directory',

  components: {
    LocationInput,
    Loader,
    SystemButton,
    PublicFacilityCard,
    PressLogos,
  },

  props: {
    filterByOpenSeats: {
      type: Boolean,
      default: true,
    },

    marketplaceName: {
      type: String,
      default: null,
    },

    marketplacePath: {
      type: String,
      default: null,
    },

    marketplaceLocation: {
      type: String,
      default: null,
    },

    marketplaceUtmParams: {
      type: String,
      default: 'utm_source=kinside&utm_medium=web&utm_campaign=public_directory',
    },

    sponsorName: {
      type: String,
      default: null,
    },

    sponsorLink: {
      type: String,
      default: null,
    },

    sponsorLogoUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      facilities: [],
      totalResults: 0,
      currentPage: 0,
      totalPages: 0,
      tooZoomedOut: false,
      sortLocation: {},
      isLoadingFacilities: false,
    };
  },

  mounted() {
    this.loadFacilities({
      open_seats: this.filterByOpenSeats,
      latlng: this.latlngFromLocation(this.sortLocation),
    });
  },

  computed: {
    showLoadMoreButton() {
      return !this.isLoadingFacilities && (this.currentPage < this.totalPages);
    },

    nextPageFacilitiesCount() {
      const pageSize = 12;
      const pagesLeft = this.totalPages - this.currentPage;

      if (pagesLeft > 1) return pageSize;
      if (pagesLeft === 1) return this.totalResults - this.facilities.length;
      return 0;
    },

    sortedByLocation() {
      return !!(this.sortLocation && this.sortLocation.lat && this.sortLocation.lon);
    },
  },

  methods: {
    loadFacilities(params) {
      this.isLoadingFacilities = true;
      axios.get(`/${this.marketplacePath}.json`, {
        params,
      })
        .then((response) => {
          this.facilities.push(...toCamelCase(response.data.markers.map((m) => m.facility)));
          this.totalResults = response.data.total_results;
          this.currentPage = response.data.current_page;
          this.totalPages = response.data.total_pages;
          this.tooZoomedOut = response.data.too_zoomed_out;
          this.isLoadingFacilities = false;
        })
        .catch(() => {
          this.isLoadingFacilities = false;
        });
    },

    setSortLocation(location) {
      this.facilities = [];
      this.sortLocation = location;
      if (typeof window.analytics !== 'undefined') {
        // eslint-disable-next-line no-undef
        window.analytics.track('Search Filtered', {
          search_type: 'child care',
          search_page: 'public directory',
          search_location: (location && location.targetAddress) ? location.targetAddress : null,
        });
      }
      this.loadFacilities({
        open_seats: this.filterByOpenSeats,
        latlng: this.latlngFromLocation(this.sortLocation),
      });
    },

    latlngFromLocation(location) {
      return (location && location.lat && location.lon) ? encodeURIComponent([location.lat, location.lon].join(',')) : null;
    },

    loadMore() {
      this.loadFacilities({
        open_seats: this.filterByOpenSeats,
        latlng: this.latlngFromLocation(this.sortLocation),
        page: this.currentPage + 1,
      });
    },

    clearLocation() {
      this.$refs.locationInput.clearLocation();
    },

    trackClickSubscribe() {
      if (typeof window.analytics !== 'undefined') {
        // eslint-disable-next-line no-undef
        window.analytics.track('Button Clicked', {
          button_label: 'Subscribe for real-time updates',
          button_location: 'section above footer',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section--sponsor {
  border-bottom: 1px solid var(--viridian-5);
  padding-top: 2px;
}

.section--public-hero {
  padding: 32px 0;
  background-color: var(--color-background-white-green);

  h1 {
    margin-bottom: 16px;
    font-family: var(--serif);
    font-size: 29px;
    font-weight: bold;
    line-height: 1.25;
    color: var(--viridian-80);
    text-align: center;

    @media (min-width: 768px) {
      font-size: 36px;
    }

    @media (min-width: 1200px) {
      font-size: 44px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    @media (min-width: 1200px) {
      font-size: 18px;
    }
  }
}

.public-directory-page {
  &__sponsor {
    text-align: center;
  }

  &__sponsor-logo {
    display: inline-block;
    height: 60px;
    margin-right: 8px;
  }

  &__sponsor-link {
    display: inline-block;
  }

  .section--public-hero {
    background: var(--color-background-white-green);

    @media (min-width: 768px) {
      background: url('https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_auto,f_auto,q_auto:good,w_342/v1693965925/app_assets/kinside_app/illustration-building-blocks.png') no-repeat, url('https://res.cloudinary.com/kinside/image/upload/c_scale,dpr_auto,f_auto,q_auto:good,w_148/v1628160942/app_assets/illustration-mom-daughter2.png') no-repeat var(--color-background-white-green);
      background-position: 130% 64%, -2% 50%;
      background-size: 342px 407px, 130px;
    }

    @media (min-width: 1200px) {
      background-position: 104% 64%, 8% 58%;
    }
  }
}

.br-small {
  display: block;

  @media (min-width: 1200px) {
    display: inline;
  }
}

.public-directory-page__location-form {
  width: 100%;
  text-align: left;

  @media (min-width: 768px) {
    width: 380px;
  }
}

.cards-wrapper {
  max-width: 356px;
  padding-bottom: 64px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 712px;
    max-width: unset;
  }

  @media (min-width: 1200px) {
    width: 1072px;
  }
}

.results-description {
  padding: 8px;
  margin-top: 24px;
  margin-bottom: 4px;
  font-weight: 400;

  p {
    font-size: 16px;
  }
}

.location-sort {
  display: inline-block;
  min-height: 32px;
  padding: 4px 8px 4px 12px;
  margin-top: 8px;
  color: var(--gold-80);
  background-color: var(--gold-5);
  border-radius: 16px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  .fa-map-marker-alt {
    margin-right: 4px;
  }
}

.btn-clear-location {
  color: var(--gold-40);
  cursor: pointer;
  background: transparent;
  border: none;
}

.load-more {
  padding: 32px 0;
  text-align: center;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
</style>
