<template>
  <div
    v-if="facility"
    class="facility-card facility-card--public"
    :id="`facilitydiv_${facilityId}`"
  >
    <div class="facility-card__top">
      <a :href="facilityUrl"
        class="facility-card__contents"
        target="_blank"
        @click="trackClickFacility"
      >
        <div class="facility-card__data-column">
          <div class="facility-card__name">
            <h5 class="facility-card__line-clamp">
              {{ facility.name }}
            </h5>

            <div class="facility-card__features">
              <feature-list
                :facility="facility"
                :public-directory="true"
                :show-distance="sortedByLocation"
                :show-city-state-zip="true"
              />
            </div>
          </div>
        </div>

        <div class="facility-card__image-column">
          <div class="facility-card__badges">
            <open-spots-badge
              v-if="hasOpenSpots"
              :public-directory="true"
            />
          </div>

          <facility-photo
            class="facility-card__image"
            :facility-id="facilityId"
            :photo-url="photoUrl"
            :google-attribution="googleAttribution"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import FacilityPhoto from '@components/facility-photo.vue';
import {
  OpenSpotsBadge,
} from '@components/badges';
import FeatureList from '@parent/components/facility-card/feature-list/feature-list.vue';

export default {
  name: 'public-facility-card',

  components: {
    FacilityPhoto,
    OpenSpotsBadge,
    FeatureList,
  },

  props: {
    facility: {
      type: Object,
      required: true,
    },

    facilityId: {
      type: Number,
      required: true,
    },

    sortedByLocation: {
      type: Boolean,
      default: false,
    },

    utmParams: {
      type: String,
      default: '',
    },
  },

  computed: {
    photoUrl() {
      return this.facility.titleImage
        || (this.facility.titleGoogleImage
          && this.facility.titleGoogleImage.photoUrl);
    },

    googleAttribution() {
      return this.facility.titleGoogleImage
        && this.facility.titleGoogleImage.htmlAttributions;
    },

    distance() {
      return (this.facility.distance || this.facility.distance === 0)
        ? `${this.facility.distance.toFixed(1)} miles` : undefined;
    },

    hasOpenSpots() {
      return this.facility.totalOpenSpotsCount > 0;
    },

    facilityUrl() {
      return `${this.facility.profileUrl}?${this.utmParams}`;
    },
  },

  methods: {
    trackClickFacility() {
      if (typeof window.analytics !== 'undefined') {
        // eslint-disable-next-line no-undef
        window.analytics.track('Card Clicked', {
          card_type: 'facility',
          facility_id: this.facilityId,
          facility_url: this.facility.legupDirectoryUrl,
          facility_name: this.facility.name,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
.facility-card {
  position: relative;
  background: var(--gray-0);
  border-radius: calc(var(--radius) * 2);
  /* stylelint-disable */
  box-shadow: 0 5px 10px 0 rgba(223, 223, 223, 0.75);
  width: 100%;
  max-width: 340px;
  min-height: 420px;
  margin: var(--grid-unit);
  /* stylelint-enable */
  transition: var(--transition) color;

  &__concierge-note {
    padding: calc(var(--grid-unit) * 2);
    border-top: var(--border) var(--gray-10);
  }

  &__top {
    display: flex;
    flex-direction: row;
  }

  &__image-column {
    width: 100%;
    min-height: 200px;
  }

  &__badges {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: auto;
    padding: var(--grid-unit);

    .tag {
      margin-bottom: var(--grid-unit);
    }
  }

  &__offers {
    margin: calc(var(--grid-unit) * 2) 0;
  }

  &__line-clamp {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__contents {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    color: var(--gray-80);
    cursor: pointer;
    user-select: none;
  }

  &__data-column {
    width: 100%;
    padding: calc(var(--grid-unit) * 2);
  }

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__name {
    overflow: hidden;

    & h5 {
      max-width: 40ch;
      padding: 0;
      margin-bottom: 0;
      font-family: var(--serif);
      font-weight: 500;
    }
  }

  &__concierge-info {
    align-items: center;
    margin-bottom: var(--grid-unit);
  }

  &__concierge-info__details {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > *:not(:empty, :last-child)::after {
      content: ' • ';
    }
  }

  &--hover,
  &:hover {
    .facility-card__name {
      color: var(--viridian-50);
    }
  }

  &:hover:active {
    .facility-card__name {
      color: var(--viridian-70);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    min-height: 200px;
    border-radius: calc(var(--radius) * 2) calc(var(--radius) * 2) 0 0;
  }

  &__google-attributions {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: calc(var(--grid-unit) / 2);
    font-size: 10px;
    color: var(--gray-0);

    &::v-deep a {
      color: var(--gray-0);
      text-decoration: underline;
    }
  }

  &__description {
    display: -webkit-box;
    min-height: 40px;
    padding: 0;
    margin-bottom: var(--grid-unit);
    color: var(--gray-50);
  }

  &__feature-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__feature-item {
    display: flex;
    align-items: baseline;
    color: var(--gray-50);

    &__icon {
      display: inline-block;
      width: calc(var(--grid-unit) * 2);
      margin-right: var(--grid-unit);
      font-family: "Font Awesome 5 Pro";
      text-align: center;
    }

    &__label {
      flex-grow: 1;
      user-select: all;
    }

    &:not(:last-child) .facility-card__feature-item__label::first-letter {
      text-transform: capitalize;
    }
  }

  &--public {
    min-height: 432px;
    border: 1px solid var(--gray-20);
    box-shadow: none;

    &:hover {
      border-color: var(--gray-30);
    }

    .facility-card__data-column {
      padding: calc(var(--grid-unit) * 3);

      .facility-card__name {
        overflow: visible;
      }

      h5 {
        margin-bottom: 0.6em;
        line-height: 1.3;
      }

      .facility-card__features {
        margin-left: -2px;
      }
    }
  }
}
</style>
